import React from 'react'

import EmissionsBreakdown from './EmissionsBreakdown'
import JourneyDetails from './JourneyDetails'
// Helpers:
import TextHelper from '../reactive/helpers/TextHelper'
// I18N:
import enums from '../reactive/i18n/enums'

// Describe the kind of vehicle used for a particular journey.
function transportVehicleText (data) {
  const displayName = enums.en.emissions.vehicleCategories[data.vehicle]
  const genericName = data.vehicle && TextHelper.humanize(data.vehicle)
  return displayName || genericName
}

// Additional details about the transportation used.
function transportVehicleInfo (data) {
  const fuelType = data.fuel && TextHelper.humanize(data.fuel)
  const currentLoad = data.totalWeight && `${data.totalWeight}kg present`
  const weightLimit = data.capacity && `${data.capacity}kg maximum`

  const foo = data.id ? `${data.modelName} ID: ${data.id}` : 'Not in database'
  const bar = [fuelType, currentLoad, weightLimit].filter(s => s).join(', ')
  return bar ? `${foo} (${bar})` : foo
}

function extraColumnFor (data) {
  const phase = data.phase ? TextHelper.humanize(data.phase) : 'Unknown'
  const party = data.partyResponsible || 'unknown'
  const title = `${phase} phase (${party} responsible)`

  let laden = null
  if (data.totalWeight && data.capacity && data.capacity > 0) {
    laden = `${Math.floor((data.totalWeight / data.capacity) * 100)}%`
  }

  return (
    <div className='job-emissions-extra-column'>
      <div className='job-emissions-phase' title={title}>{party}</div>
      <div className='job-emissions-vehicle' title={transportVehicleInfo(data)}>
        {transportVehicleText(data)}
      </div>
      <div className='job-emissions-laden'>{laden}</div>
    </div>
  )
}

// Used to display details for a given job.
function JobBreakdown (props) {
  let wttTotal = 0
  let ttwTotal = 0

  const items = props.job.breakdown.map((data, idx) => {
    const km = data.actualDistance || data.estimatedDistance || 0
    const tkm = ((props.job.grossWeightKg || 0) / 1000.0) * km

    let wttValue = null
    let ttwValue = null
    const wttExplanation = []
    const ttwExplanation = []

    // Ignore any data for the FIRST location; there is no journey "to" it.
    if (idx) {
      wttValue = 0
      ttwValue = 0

      Object.keys(data.factors).forEach(key => {
        if (key === 'km') { return }

        wttValue += data.factors[key].wtt * tkm
        ttwValue += data.factors[key].ttw * tkm

        wttExplanation.push(`${key} = ${data.factors[key].wtt}`)
        ttwExplanation.push(`${key} = ${data.factors[key].ttw}`)
      })

      wttTotal += wttValue
      ttwTotal += ttwValue
    }

    return (
      <JourneyDetails
        key={idx}
        data={data}
        wtt={wttValue}
        ttw={ttwValue}
        wttExplanation={wttExplanation.join(' / ')}
        ttwExplanation={ttwExplanation.join(' / ')}
        weight={idx ? props.job.grossWeightKg : null}
        extraColumn={idx ? extraColumnFor(data) : null}
      >
        {data.refrigerated && (
          <i className='fa fa-snowflake-o text-primary' title='Refrigerated' />
        )}
      </JourneyDetails>
    )
  })

  return (
    <EmissionsBreakdown label='Transport Type' wtt={wttTotal} ttw={ttwTotal}>
      {items}
    </EmissionsBreakdown>
  )
}

export default JobBreakdown
