import React from 'react'

import EmissionsBreakdown from './EmissionsBreakdown'
import JourneyDetails from './JourneyDetails'

function capacityTextFor (waypoint) {
  if (!waypoint.totalWeight || !waypoint.capacity) { return null }

  const value = (waypoint.totalWeight / waypoint.capacity) * 100
  return `${value.toFixed(2)}%`
}

function effectiveWeightAt (route, startIndex) {
  // Ferry/Train look at the NEXT location with trailer-type vehicle details...
  const destination = route.slice(startIndex).find(place => place.factors.km)
  // ...and use its weight-upon-arrival value as the currently-carried weight:
  return destination ? destination.totalWeight : null
}

// Used to display details for a given vehicle.
function VehicleBreakdown (props) {
  let wttTotal = 0
  let ttwTotal = 0

  const items = props.data.map((data, idx) => {
    const kg = effectiveWeightAt(props.data, idx)
    const km = data.actualDistance || data.estimatedDistance || 0
    const tkm = ((kg || 0) / 1000.0) * km

    const wttExplanation = []
    const ttwExplanation = []

    let wttValue = 0
    let ttwValue = 0

    if (data.factors.km) {
      wttValue = data.factors.km.wtt * km
      ttwValue = data.factors.km.ttw * km

      wttExplanation.push(`km = ${data.factors.km.wtt}`)
      ttwExplanation.push(`km = ${data.factors.km.ttw}`)
    } else {
      Object.keys(data.factors).forEach(key => {
        wttValue += data.factors[key].wtt * tkm
        ttwValue += data.factors[key].ttw * tkm

        wttExplanation.push(`${key} = ${data.factors[key].wtt}`)
        ttwExplanation.push(`${key} = ${data.factors[key].ttw}`)
      })
    }

    wttTotal += wttValue
    ttwTotal += ttwValue

    return (
      <JourneyDetails
        key={idx}
        data={data}
        wtt={wttValue}
        ttw={ttwValue}
        wttExplanation={wttExplanation.join(' / ')}
        ttwExplanation={ttwExplanation.join(' / ')}
        weight={kg}
        extraColumn={capacityTextFor(data)}
      >
        {data.vehicle === 'freight_train' && (
          <i className='fa fa-train' title='Freight train' />
        )}
        {data.vehicle === 'roro_ferry' && (
          <i className='fa fa-anchor' title='Ferry' />
        )}
      </JourneyDetails>
    )
  })

  return (
    <EmissionsBreakdown label='Capacity' wtt={wttTotal} ttw={ttwTotal}>
      {items}
    </EmissionsBreakdown>
  )
}

export default VehicleBreakdown
