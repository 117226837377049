import React from 'react'

import EmissionsHelper from '../reactive/helpers/EmissionsHelper'

// Displays details for an individual (leg of a) journey.
function JourneyDetails (props) {
  const km = props.data.actualDistance || props.data.estimatedDistance
  const miles = EmissionsHelper.kilometresToMiles(km)

  const tkm = EmissionsHelper.calculateTonneKilometres(props.data, props.weight)
  const wtw = props.wtt && props.ttw ? props.wtt + props.ttw : null

  const intensityRatio = wtw && tkm && `${(wtw / tkm).toFixed(2)} kg`
  const wtwTitleText = `Intensity ratio: ${intensityRatio || 'N/A'}`

  // Include links to Google Maps and the Open Street Map website, if possible:
  const lat = props.data.latitude
  const lon = props.data.longitude
  const osm = `https://openstreetmap.org/?mlat=${lat}&mlon=${lon}`
  const gmaps = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`

  return (
    <div className='journey-details'>
      <div className='journey-details-location'>
        {lat && lon ? (
          <a className='journey-details-osm' title='View on Open Street Map'
            href={osm} target='_blank'>
            OSM
          </a>
        ) : (
          <span className='journey-details-osm' title='Geolocation failure'>
            OSM
          </span>
        )}
        {lat && lon ? (
          <a className='journey-details-gmaps' title='View on Google Maps'
            href={gmaps} target='_blank'>
            Google
          </a>
        ) : (
          <span className='journey-details-gmaps' title='Geolocation failure'>
            Google
          </span>
        )}
        <span className='journey-details-reference'>
          {props.data.reference}
        </span>
      </div>
      <div className='journey-details-misc'>{props.extraColumn}</div>
      <div className='journey-details-distance'>
        <span title={miles && `${EmissionsHelper.formatDistance(miles)} miles`}>
          {EmissionsHelper.formatDistance(km)}
        </span>
        {(props.data.estimatedDistance && !props.data.actualDistance) ? (
          <i
            className='fa fa-exclamation-triangle text-warning estimate-warning'
            title='Estimated'
          />
        ) : null}
      </div>
      <div className='journey-details-weight'>
        <span title={tkm && `${tkm.toFixed(2)} t-km`}>{props.weight}</span>
      </div>
      <div className='journey-details-wtt' title={props.wttExplanation || null}>
        {props.wtt?.toFixed(2)}
      </div>
      <div className='journey-details-ttw' title={props.ttwExplanation || null}>
        {props.ttw?.toFixed(2)}
      </div>
      <div className='journey-details-emissions' title={wtwTitleText}>
        {wtw?.toFixed(2)}
      </div>
      <div className='journey-details-special'>{props.children}</div>
    </div>
  )
}

export default JourneyDetails
